import React from "react"
import {
  Row,
  Col,
  Container,
  Button,
  Card,
  Image,
  Accordion,
  Navbar,
} from "react-bootstrap"
import {
  FaCheckCircle,
  FaWhatsapp,
  FaTags,
  FaTimesCircle,
} from "react-icons/fa"
import LazyLoad from "react-lazyload"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Countdown from "react-countdown"
// import Countdown from 'react-countdown'

export default function IndexPage() {
  return (
    <>
      <Layout pageInfo={{ pageName: "Emotional Mastery" }}>
        <SEO
          title="Emotional Mastery"
          keywords={[`Emotional Mastery`, `Emotional`, `Mastery`]}
        />
        <LazyLoad>
          <Navbar
            fixed="top"
            className="bg-navbar-gradient-em"
            variant="danger"
            bg="danger"
          >
            <Container className="d-flex-center-em-sl">
              <Navbar.Brand
                href="#home"
                className="text-white text-mobile-cd-sl text-center"
              >
                Penawaran Akan Berakhir :
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse className="justify-content-end">
                <Navbar.Text>
                  <Countdown
                    className="text-white __cd-time-wl-em"
                    date={"2022-04-26 20:00"}
                  />
                </Navbar.Text>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </LazyLoad>
        {/* Section one */}
        <LazyLoad>
          <div className="jumbotron bg-em-primary">
            <Row className="__em-content-align-center">
              <Col lg={12}>
                <div className="__em-logo-lc">
                  {/* set logo love coach */}
                  <div className="d-flex justify-content-center">
                    <Image
                      className="img-fluid"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-lc.png"
                    />
                  </div>
                  {/* end set logo love coach */}
                </div>
              </Col>
              <Col lg={12} md={12} sm={12}>
                <div className="__em-logo-emotional">
                  <Image
                    className="img-fluid"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-em.png"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </LazyLoad>
        <LazyLoad>
          <Container>
            <Row className="justify-content-center __margin-top-mock-up">
              <Col lg={12} md={12} sm={12}>
                <div className="__em-mockup-device">
                  <Image
                    className="img-fluid"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/EM-mockup-device.png"
                  />
                </div>
              </Col>
              <div className="__em-button-signUp">
                <a href="#pricingtop">
                  <Button variant="primary" size="md">
                    Ya Saya Mau Daftar!
                  </Button>
                </a>
                <a href="#pricingtop">
                  <p className="text-center text-drak pt-2">
                    (Klik di sini untuk instan akses sekarang)
                  </p>
                </a>
              </div>
            </Row>
          </Container>
        </LazyLoad>
        {/* end Section one */}
        {/* Section two */}
        <LazyLoad>
          <Container className="bg-em-secondary">
            <Row>
              <Col lg={6} md={6} sm={12}>
                <div className="__em-img-girl-think">
                  <Image
                    className="img-fluid"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/women-think.jpg"
                  />
                </div>
              </Col>
              <Col lg={6} md={5} sm={12}>
                <div className="__em-wrappe-wording">
                  <h2>
                    <b>STOP! PERINGATAN</b>
                  </h2>
                  <p>
                    Training ini bukan tentang menghilangkan emosi "negatif".
                    Bukan bagaimana cara kamu
                  </p>
                  <p>
                    menghilangkan rasa sedih, marah, takut, stress, benci, lelah
                    dan khawatir
                  </p>
                  <p>
                    Tapi soal <b>mengendalikan dan memanfaatkan emosi</b> yang
                    kamu punya. Yang awalnya kamu
                  </p>
                  <p>
                    kira menghambat tapi justru berguna membantumu mencapai
                    tujuan apapun hanya karena
                  </p>
                  <p>
                    kamu <b>tau "cara pakainya"..</b>
                  </p>
                  <p>
                    <i>Bayangkan ketika..</i>
                  </p>
                  <p>Kamu pergi ke mall untuk membeli pakaian baru</p>
                  <p>
                    Ketika sedang merasa senang dan bahagia kamu pasti cenderung
                    memilih pakaian dengan warna-warna yang cerah sebagai
                    pilihanmu
                  </p>
                  <p>
                    Sebaliknya, ketika perasaan kamu sedang sedih, resah, takut,
                    stress, cemas kamu lebih memilih pakaian dengan warna yang
                    gelap
                  </p>
                  <p>
                    Sama halnya dengan emosi, kita cenderung mengeluarkan emosi
                    sesuai dengan apa yang kita rasakan
                  </p>
                  <p>Tapi terkadang, ekspektasi gak sesuai kenyataan..</p>
                  <p>Karena kamu gak mengendalikan "remote control"nya.</p>
                  <p>
                    Alhasil, maksud hati <b>ingin bisa fokus bekerja</b> malah
                    kacau berantakan..
                  </p>
                  <p>
                    <b>Berharap bisa happy di romansa</b> malah sering konflik
                    tanpa ada baikan..
                  </p>
                  <p>
                    Pengen <b>punya mood bagus supaya bisa produktif</b> tapi
                    ada trigger sedikit, mood seharian bisa rusak gak ada obat.
                  </p>
                  <p>Hmm..</p>
                  <p>
                    Bagaimana jika ada skill yang bisa kamu pelajari agar{" "}
                    <b>mampu MEMILIH emosi yang tepat sesuai kebutuhan</b>{" "}
                    semudah kamu memilih warna baju yang ingin kamu pakai.
                  </p>
                  <p>Tertarik menguasainya? :)</p>
                </div>
              </Col>
            </Row>

            <Row className="__em-section-any-problem">
              <Col lg={12} xs={12}>
                <h2 className="text-center">
                  Seberapa sering kamu mengalami hal ini?
                </h2>
              </Col>
              <div className="__em-mx-section">
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>Gak bisa menyampaikan emosi yang kamu rasakan</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>
                      Galau karena kenyataan selalu berbeda dengan ekspektasi
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>
                      Sedih, marah dan kecewa berlarut akibat masalah yang gak
                      kunjung selesai
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>
                      Kesulitan mengontrol emosi hingga akhirnya kamu dan
                      pasangan malah saling menyakiti
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>
                      Sering memendam emosi karena takut dianggap jahat jika
                      mengungkapkannya
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs={11}>
                    <p>
                      Menyesal sering salah mengambil keputusan ketika sedang
                      emosi
                    </p>
                  </Col>
                </Row>
              </div>
            </Row>
          </Container>
        </LazyLoad>
        {/* End section two */}
        {/* Section three */}
        <LazyLoad>
          <div className="jumbroton em-bg-gradient">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="__em-logo-lc">
                    {/* set logo love coach */}
                    <div className="d-flex justify-content-center">
                      <Image
                        className="img-fluid"
                        src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-lc.png"
                      />
                    </div>
                    {/* end set logo love coach */}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="__em-logo-emotional">
                    <Image
                      className="img-fluid"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-em.png"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="__em-text-join-class">
                    <h2 className="text-center text-white pt-3">
                      Ketika Join Kelas Ini, Kamu Akan Dapatkan:
                    </h2>
                  </div>
                  <Container>
                    <Row className="mt-5">
                      <Col lg={12}>
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col lg={12}>
                                <div className="__em-headline-text-join-class">
                                  {/* <h4 className="text-center pt-3">
                                    <b>
                                      1. VIP CLASS Emotional Mastery Online
                                      Training
                                    </b>
                                  </h4> */}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="__em-wrappe-icon-join-class">
                                  <Image
                                    className="img-fluid"
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/EM-mockup-device.png"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Row className="__em-mx-section-join-class">
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Punya skill mengontrol emosi yang
                                          tidak pernah kamu dapatkan sebelumnya
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Memahami emosi kamu sendiri disaat
                                          tidak ada satupun yang bisa kamu ajak
                                          bicara..
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Miliki hubungan romansa yang sehat,
                                          saling mengerti, minim drama dan hemat
                                          air mata..
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Mampu memahami dan menyampaikan
                                          ekspektasi yang selama ini sering
                                          bikin kalian salah paham
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Meminimalisir stress akibat mood swing
                                          yang selama ini gak bisa kamu kontrol
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Tetap tenang menghadapi apapun
                                          masalahmu hari ini, entah pekerjaan,
                                          bisnis, romansa, masalah keluarga, dan
                                          sebagainya.
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            {/* <Row>
                              <Col lg={6}>
                                <div className="__em-wrappe-icon-join-class __em-style-image">
                                  <Image
                                    className="img-fluid"
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Workbook-Mockup.png"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Row className="__em-mx-section-join-class">
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Berisi rangkuman materi yang
                                          memudahkanmu tidak perlu repot-repot
                                          membuat rangkuman sendiri.
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="__em-wrappe-icon-join-class __em-style-image">
                                  <Image
                                    className="img-fluid"
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Cheatsheet-Mockup.png"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Row className="__em-mx-section-join-class">
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Berisi contekan visual yang
                                          memudahkanmu memahami isi materi.
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="__em-wrappe-icon-join-class __em-style-image">
                                  <Image
                                    className="img-fluid"
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Audio-Record-Mockup.png"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Row className="__em-mx-section-join-class">
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Akses audio rekaman selama 30 hari
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Kamu bisa mengulang kembali materi
                                          dalam bentuk audio
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="__em-wrappe-icon-join-class __em-style-image">
                                  <Image
                                    className="img-fluid"
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Q&A-Session-Mockup.png"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Row className="__em-mx-section-join-class">
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Sesi khusus 60 menit bersama Coach
                                          Jose Aditya & Coach Irvan Irawan Jie
                                          dimana kamu dan peserta VIP lainnya
                                          mendapatkan privilege Q & A mengenai
                                          materi yang tidak didapat di kelas
                                          Reguler
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row> */}
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    {/* <Row className="mt-5">
                      <Col lg={12}>
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col lg={12}>
                                <div className="__em-headline-text-join-class">
                                  <h4 className="text-center pt-3">
                                    <b>
                                      2. Regular CLASS Emotional Mastery Online
                                      Training
                                    </b>
                                  </h4>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-5">
                              <Col lg={6}>
                                <div className="__em-wrappe-icon-join-class">
                                  <Image
                                    className="img-fluid"
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/EM-mockup-device.png"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Row className="__em-mx-section-join-class">
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Punya skill mengontrol emosi yang
                                          tidak pernah kamu dapatkan sebelumnya
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Memahami emosi kamu sendiri disaat
                                          tidak ada satupun yang bisa kamu ajak
                                          bicara..
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Miliki hubungan romansa yang sehat,
                                          saling mengerti, minim drama dan hemat
                                          air mata..
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Mampu memahami dan menyampaikan
                                          ekspektasi yang selama ini sering
                                          bikin kalian salah paham
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Meminimalisir stress akibat mood swing
                                          yang selama ini gak bisa kamu kontrol
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Tetap tenang menghadapi apapun
                                          masalahmu hari ini, entah pekerjaan,
                                          bisnis, romansa, masalah keluarga, dan
                                          sebagainya.
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="__em-wrappe-icon-join-class __em-style-image">
                                  <Image
                                    className="img-fluid"
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Workbook-Mockup.png"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Row className="__em-mx-section-join-class">
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Berisi rangkuman materi yang
                                          memudahkanmu tidak perlu repot-repot
                                          membuat rangkuman sendiri.
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col lg={6}>
                                <div className="__em-wrappe-icon-join-class __em-style-image">
                                  <Image
                                    className="img-fluid"
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Audio-Record-Mockup.png"
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <Row className="__em-mx-section-join-class">
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>Akses audio rekaman selama 3 hari</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col lg={12}>
                                    <Row>
                                      <Col lg={1} xs={1}>
                                        <FaCheckCircle className="text-success" />
                                      </Col>
                                      <Col xs>
                                        <p>
                                          Kamu bisa mengulang kembali materi
                                          dalam bentuk audio
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row> */}
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
        </LazyLoad>
        {/* end Section thre */}
        {/* Section four */}
        <LazyLoad>
          <Container className="bg-em-secondary">
            <Row>
              <Col lg={12}>
                <div className="__em-how-to-payment">
                  <h2 className="text-center">
                    <b>
                      Pilih Durasi Membershipmu
                      {/* Untuk Join Online Training Emotional Mastery, Ikuti
                      Langkah Berikut Ini : */}
                    </b>
                  </h2>
                </div>
              </Col>
            </Row>
            {/* <Row className="mt-5">
              <Col lg={4}>
                <Card className="my-3">
                  <div className="__wrappe-rounded-icons">
                    <Card.Img
                      variant="top"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1daftar-min-v.png"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="text-center">Langkah #1</p>
                    </Card.Title>
                    <Card.Text>
                      <p className="text-center">
                        {" "}
                        Klik salah satu tombol daftar
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={4}>
                <Card className="my-3">
                  <div className="__wrappe-rounded-icons">
                    <Card.Img
                      variant="top"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1daftar-kelas-min-v.png"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      {" "}
                      <p className="text-center">Langkah #2</p>
                    </Card.Title>
                    <Card.Text>
                      <p className="text-center">
                        Pilih salah satu kelas : VIP atau Reguler
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={4}>
                <Card className="my-3">
                  <div className="__wrappe-rounded-icons">
                    <Card.Img
                      variant="top"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1isi-form-min-v.png"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="text-center">Langkah #3</p>
                    </Card.Title>
                    <Card.Text>
                      <p className="text-center">
                        Lakukan pengisian data dengan benar
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={4}>
                <Card className="my-3">
                  <div className="__wrappe-rounded-icons">
                    <Card.Img
                      variant="top"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1transfer-min-v.png"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="text-center">Langkah #4</p>
                    </Card.Title>
                    <Card.Text>
                      <p className="text-center">
                        Lakukan pembayaran sebelum batas transfer habis
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={4}>
                <Card className="my-3">
                  <div className="__wrappe-rounded-icons">
                    <Card.Img
                      variant="top"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1konfirmasi.png"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="text-center">Langkah #5</p>
                    </Card.Title>
                    <Card.Text>
                      <p className="text-center">
                        Lakukan Konfirmasi pembayaran
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>

              <Col lg={4}>
                <Card className="my-3">
                  <div className="__wrappe-rounded-icons">
                    <Card.Img
                      variant="top"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/1cek-email-min-v.png"
                    />
                  </div>
                  <Card.Body>
                    <Card.Title>
                      <p className="text-center">Langkah #6</p>
                    </Card.Title>
                    <Card.Text>
                      <p className="text-center">
                        Cek email (inbox/spam/promotion) untuk lihat info link
                        training
                      </p>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row> */}

            <Row className="_em-row-pricing" id="pricingtop">
              {/* <Col lg={6}>
                <div className="__em-pricing-spesial-offer">
                  <div className="__em-logo-spesial-offer">
                    <Image
                      className="img-fluid"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/spesial-offer-badge.png"
                    />
                  </div>
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">VIP CLASS NORMAL OFFER</h4>
                    <p className="text-center">( 4 - 10 September )</p>
                  </div>
                  <div className="__wrappe-text-desc-pricing">
                    <p class="text-center">Diadakan Hanya 1 Tahun Sekali</p>
                    <h3 className="text-center">
                      <del>Rp 749.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 399.000</h2>
                    <p className="text-center">
                      <b>DISC 46%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          3 Jam Online Training (via Zoom){" "}
                          <b>
                            Sabtu, 11 September 2021 Pukul 09.00 - 12.00 WIB
                          </b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>Workbook</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>Visual Cheat Sheet</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>Akses Audio Rekaman selama 30 hari</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          60 Menit Q & A (via Zoom){" "}
                          <b>
                            Sabtu, 11 September 2021 Pukul 13.00 - 14.00 WIB
                          </b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          Miliki kesempatan menangkan{" "}
                          <b>Lucky Draw buku Buat Dia Terus Kembali</b> setiap
                          hari senilai Rp 199.000
                        </p>
                      </Col>
                    </Row>
                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://JoseAditya.zaxaa.com/o/14606844859772/1"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Daftar VIP
                            Class
                          </Button>{" "}
                        </a>
                      </Col>
                      <Col lg={12}>
                        <a
                          href="https://wa.link/le51ri"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Fakhri
                          </Button>{" "}
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col> */}
              <Col lg={4} md={4}>
                <div className="__em-pricing-spesial-offer mrgn-reg">
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">
                      VIP Membership <br /> 1 BULAN
                    </h4>
                    {/* <p className="text-center">( 4 - 10 September )</p> */}
                  </div>
                  <div className="__wrappe-text-desc-pricing">
                    <h3 className="text-center">
                      <del>Rp 212.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 199.000</h2>
                    <p className="text-center">
                      <b>Hemat 7%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access VIP Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access Premium Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access FREE Course​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Monthly QnA With Coaches​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Exclusive Live Webinar​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Support system by LavSquad​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Group Challenge​</b>
                        </p>
                      </Col>
                    </Row>
                    <p class="text-center">
                      Semua benefit diatas selama 1 bulan
                    </p>
                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://cart.lovecoach.id/product/vip-1-bulan-thr-spesial-offer"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Join
                            Sekarang
                          </Button>{" "}
                        </a>
                      </Col>
                      {/* <Col lg={12}>
                        <a
                          href="https://wa.link/g2h88l"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Desma
                          </Button>{" "}
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="__em-pricing-spesial-offer mrgn-reg">
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">
                      VIP Membership <br /> 3 BULAN
                    </h4>
                    {/* <p className="text-center">( 4 - 10 September )</p> */}
                  </div>
                  <div className="__wrappe-text-desc-pricing">
                    <h3 className="text-center">
                      <del>Rp 636.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 499.000</h2>
                    <p className="text-center">
                      <b>Hemat 20%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access VIP Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access Premium Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access FREE Course​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Monthly QnA With Coaches​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Exclusive Live Webinar​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Support system by LavSquad​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Group Challenge​</b>
                        </p>
                      </Col>
                    </Row>
                    <p class="text-center">
                      Semua benefit diatas selama 3 bulan
                    </p>
                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://cart.lovecoach.id/product/vip-3-bulan-thr-spesial-offer"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Join
                            Sekarang
                          </Button>{" "}
                        </a>
                      </Col>
                      {/* <Col lg={12}>
                        <a
                          href="https://wa.link/g2h88l"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Desma
                          </Button>{" "}
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="__em-pricing-spesial-offer mrgn-reg">
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">
                      VIP Membership <br /> 12 BULAN
                    </h4>
                    {/* <p className="text-center">( 4 - 10 September )</p> */}
                  </div>
                  <div className="__wrappe-text-desc-pricing">
                    <h3 className="text-center">
                      <del>Rp 2.200.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 1.499.000</h2>
                    <p className="text-center">
                      <b>Hemat 30%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access VIP Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access Premium Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access FREE Course​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Monthly QnA With Coaches​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Exclusive Live Webinar​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Support system by LavSquad​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Group Challenge​</b>
                        </p>
                      </Col>
                    </Row>
                    <p class="text-center">
                      Semua benefit diatas selama 12 bulan
                    </p>
                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://cart.lovecoach.id/product/vip-12-bulan-thr-spesial-offer"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Join
                            Sekarang
                          </Button>{" "}
                        </a>
                      </Col>
                      {/* <Col lg={12}>
                        <a
                          href="https://wa.link/g2h88l"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Desma
                          </Button>{" "}
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="__em-section-any-problem">
              <Col lg={12} xs={12}>
                <h2 className="text-center">Tahukah kamu,</h2>
              </Col>
              <div className="__em-mx-section">
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      Pertengkaran menjadi penyebab perceraian NOMOR SATU dengan
                      persentase 58℅ (Ditjen Badan Pengadilan Agama Mahkamah
                      Agung, 2020)
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      {" "}
                      Kebencian terhadap diri sendiri menjadi pemicu seseorang
                      ingin mengakhiri hidupnya (sumber: helpguide.org)
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      Dan masalah personal yang tidak teratasi bisa menurunkan
                      produktivitas.
                    </p>
                  </Col>
                </Row>
                <Col lg={12} xs={12}>
                  <h2 className="text-center">Hiks.. :(</h2>
                </Col>
              </div>
            </Row>

            <Row className="__margin-top-bottom">
              <Col lg={6} md={6} sm={12}>
                <div className="__em-img-girl-think">
                  <Image
                    className="img-fluid"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/happines.jpg"
                  />
                </div>
              </Col>
              <Col lg={6} md={5} sm={12}>
                <div className="__em-wrappe-wording">
                  <p>
                    Padahal, jika suami dan istri mampu mengerti EMOSI mereka
                    masing-masing dan tau cara menyampaikannya, mereka gak perlu
                    saling menyakiti..
                  </p>
                  <p>
                    Seandainya, kamu tau "aku tuh lagi kenapa sih? Lagi merasa
                    apa?" dan bisa mengendalikan emosi meskipun sedang tidak ada
                    orang yang bisa kamu ajak bicara, mengakhiri hidup gak akan
                    pernah terlintas di pikiranmu.
                  </p>
                  <p>
                    Jika kita tau gimana caranya menggunakan emosi sesuai
                    kebutuhan, gangguan mood yang berantakan bisa kita hindari.
                  </p>
                  <p>
                    Kesimpulannya,{" "}
                    <b>
                      skill mengendalikan emosi adalah skill yang bisa mengubah
                      hidup.
                    </b>
                  </p>
                  <p>
                    Karenanya, izinkan saya sharing sebuah cara yang bisa
                    membantumu keluar dari kondisi frustasi selama ini.
                  </p>
                  <p>
                    Agar kamu bisa lebih{" "}
                    <b>menguasai skill mengendalikan emosi.</b>
                  </p>
                  <p>
                    Sampai jumpa di Online Training <b>Emotional Mastery</b>
                  </p>
                  <p>
                    <b>Better Emotion Better Love Life</b>
                  </p>
                  <p>
                    Dan pada akhirnya, kamu bisa mengendalikan emosi negatif dan
                    positif untuk progress hidup yang lebih baik lagi
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="__em-section-any-problem">
              <Col lg={12} xs={12}>
                <h2 className="text-center">
                  Kini Saatnya Kamu Berhenti Dari Kesalahan:
                </h2>
              </Col>
              <div className="__em-mx-section">
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>
                      Terjebak dari hubungan yang penuh drama dan jadi bucin
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>
                      {" "}
                      Merasa tertekan dan gak waras menghadapi masalah yang kamu
                      punya
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>
                      Mengungkapkan emosi secara meledak-ledak namun hanya
                      memperburuk keadaan
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>Kebingungan mengendalikan emosi yang tidak menentu</p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>
                      Memendam perasaan dan berharap keajaiban mengubah keadaan
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaTimesCircle className="text-danger" />
                  </Col>
                  <Col xs>
                    <p>Ekspektasi yang hanya berujung kekecewaan</p>
                  </Col>
                </Row>
                <Col lg={12} xs={12}>
                  <p className="text-center pt-5">
                    Jika kamu mulai merasa lelah dengan masalah yang itu itu
                    aja, ingin kualitas hidup yang bahagia. Maka sekarang kamu
                    mulai sadar kualitas emosi kamu tidak sesuai dengan kualitas
                    hidupmu.
                  </p>
                  <p className="text-center">
                    Jadi tidak ada lagi alasan untuk menunda-nunda{" "}
                    <b>join Online Training Emotional Mastery</b>
                  </p>
                </Col>
              </div>
            </Row>
          </Container>
        </LazyLoad>
        {/* end Section four */}
        {/* Section five */}
        <LazyLoad>
          <div className="jumbroton em-bg-gradient">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="__em-logo-lc">
                    {/* set logo love coach */}
                    <div className="d-flex justify-content-center">
                      <Image
                        className="img-fluid"
                        src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-lc.png"
                      />
                    </div>
                    {/* end set logo love coach */}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="__em-logo-emotional">
                    <Image
                      className="img-fluid"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-em.png"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="__em-text-join-class">
                    <h2 className="text-center text-white pt-3">
                      Apa saja yang Kamu pelajari setelah ikut training ini?
                    </h2>
                  </div>
                  <Container>
                    <Row className="mt-5">
                      <Col lg={4}>
                        <Card className="mb-3">
                          <Card.Body>
                            <Card.Title className="text-center">
                              <b>Secret #1</b>
                            </Card.Title>

                            <Card.Text>
                              <Row>
                                <Col lg={12}>
                                  <p className="text-center">
                                    Mengenal Emosi Sesungguhnya
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={1} xs={1}>
                                  <FaCheckCircle className="text-success" />
                                </Col>
                                <Col>
                                  6 mitos yang sering dipercaya soal emosi
                                </Col>
                              </Row>
                              <Row className="my-3">
                                <Col lg={1} xs={1}>
                                  <FaCheckCircle className="text-success" />
                                </Col>
                                <Col>9 sifat emosi yang perlu kamu tau!</Col>
                              </Row>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card className="mb-3">
                          <Card.Body>
                            <Card.Title className="text-center">
                              <b>Secret #2</b>
                            </Card.Title>

                            <Card.Text>
                              <Row>
                                <Col lg={12}>
                                  <p className="text-center">“Pabrik” Emosi</p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={1} xs={1}>
                                  <FaCheckCircle className="text-success" />
                                </Col>
                                <Col>
                                  Mengapa kamu sering dikendalikan emosi yang
                                  tidak kamu inginkan
                                </Col>
                              </Row>
                              <Row className="my-3">
                                <Col lg={1} xs={1}>
                                  <FaCheckCircle className="text-success" />
                                </Col>
                                <Col>
                                  Bagaimana “menciptakan” emosi yang kamu mau
                                </Col>
                              </Row>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={4}>
                        <Card className="mb-3">
                          <Card.Body>
                            <Card.Title className="text-center">
                              <b>Secret #3</b>
                            </Card.Title>

                            <Card.Text>
                              <Row>
                                <Col lg={12}>
                                  <p className="text-center">
                                    Cara Mengendalikan Emosi
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={1} xs={1}>
                                  <FaCheckCircle className="text-success" />
                                </Col>
                                <Col>6 Langkah Mengendalikan Emosi</Col>
                              </Row>
                              <Row className="my-3">
                                <Col lg={1} xs={1}>
                                  <FaCheckCircle className="text-success" />
                                </Col>
                                <Col>
                                  Bagaimana kualitas emosi bisa mengubah
                                  kualitas hubungan jauh lebih baik
                                </Col>
                              </Row>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
        </LazyLoad>
        {/* end Section five */}
        {/* Section six */}
        <LazyLoad>
          <Container className="bg-em-secondary">
            <Row className="__boder-em-why">
              <Col lg={4}>
                <div className="__wrappe-images-why-em">
                  <Image
                    className="img-fluid"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/women-2.jpg"
                  />
                </div>
              </Col>
              <Col lg={8}>
                <div className="__wrappe-desc-text-why-em">
                  <h3>
                    Mengapa Emotional Mastery berguna untuk mereka yang belum
                    punya pasangan?
                  </h3>
                  <p>
                    Agar wanita single memahami cara mengelola emosinya sendiri
                    dan paham pria yang cocok untuknya. Sehingga bisa
                    meminimalisir konflik setelah menikah
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="__boder-em-why">
              <Col lg={4}>
                <div className="__wrappe-images-why-em">
                  <Image
                    className="img-fluid"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/couple-2.jpg"
                  />
                </div>
              </Col>
              <Col lg={8}>
                <div className="__wrappe-desc-text-why-em">
                  <h3>
                    Mengapa Emotional Mastery berguna untuk mereka yang sudah
                    punya pasangan?
                  </h3>
                  <p>
                    Ikut Emotional Mastery bisa mengontrol ekspektasi terhadap
                    pasangan dan paham bagaimana cara menyampaikan emosi
                    sehingga konflik yang dialami tidak berkepanjangan dan
                    membuat hubungan kalian “naik kelas”
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="__margin-top-bottom">
              <Col lg={12}>
                <div className="__wrappe-who-pricing">
                  <h2 className="text-center">
                    <b>Untuk Menyelesaikan Pendaftaran</b>
                  </h2>
                  <h3 className="text-center">
                    <b>Pilih Durasi Membershipmu</b>
                    {/* Yang perlu saya lakukan hanyalah <b>KLIK DAFTAR</b> yang ada
                    di bawah serta mengisi form order dan mengkonfirmasi
                    pembayaran untuk terdaftar sebagai peserta{" "}
                    <b>Online Training Emotional Mastery!</b> */}
                  </h3>
                </div>
              </Col>
            </Row>

            <Row className="_em-row-pricing" id="pricingbottom">
              {/* <Col lg={6}>
                <div className="__em-pricing-spesial-offer">
                  <div className="__em-logo-spesial-offer">
                    <Image
                      className="img-fluid"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/spesial-offer-badge.png"
                    />
                  </div>
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">VIP CLASS NORMAL OFFER</h4>
                    <p className="text-center">( 4 - 10 September )</p>
                  </div>
                  
                  <div className="__wrappe-text-desc-pricing">
                    <p class="text-center">Diadakan Hanya 1 Tahun Sekali</p>
                    <h3 className="text-center">
                      <del>Rp 749.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 399.000</h2>
                    <p className="text-center">
                      <b>DISC 46%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          3 Jam Online Training (via Zoom){" "}
                          <b>
                            Sabtu, 11 September 2021 Pukul 09.00 - 12.00 WIB
                          </b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>Workbook</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>Visual Cheat Sheet</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>Akses Audio Rekaman selama 30 hari</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          60 Menit Q & A (via Zoom){" "}
                          <b>
                            Sabtu, 11 September 2021 Pukul 13.00 - 14.00 WIB
                          </b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          Miliki kesempatan menangkan{" "}
                          <b>Lucky Draw buku Buat Dia Terus Kembali</b> setiap
                          hari senilai Rp 199.000
                        </p>
                      </Col>
                    </Row>
                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://JoseAditya.zaxaa.com/o/14606844859772/1"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Daftar VIP
                            Class
                          </Button>{" "}
                        </a>
                      </Col>
                      <Col lg={12}>
                        <a
                          href="https://wa.link/le51ri"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Fakhri
                          </Button>{" "}
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col> */}
              {/* <Col lg={6}>
                <div className="__em-pricing-spesial-offer mrgn-reg">
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">REGULER CLASS NORMAL OFFER</h4>
                    <p className="text-center">( 4 - 10 September )</p>
                  </div>
                  <div className="__wrappe-text-desc-pricing">
                    <p class="text-center">Diadakan Hanya 1 Tahun Sekali</p>
                    <h3 className="text-center">
                      <del>Rp 499.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 299.000</h2>
                    <p className="text-center">
                      <b>DISC 40%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          3 Jam Online Training (via Zoom){" "}
                          <b>
                            Sabtu, 11 September 2021 Pukul 09.00 - 12.00 WIB
                          </b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>Workbook</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>Akses Audio Rekaman selama 3 hari</p>
                      </Col>
                    </Row>

                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://JoseAditya.zaxaa.com/o/14606831359773/1"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Daftar REG
                            Class
                          </Button>{" "}
                        </a>
                      </Col>
                      <Col lg={12}>
                        <a
                          href="https://wa.link/g2h88l"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Desma
                          </Button>{" "}
                        </a>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col> */}
              <Col lg={4} md={4}>
                <div className="__em-pricing-spesial-offer mrgn-reg">
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">
                      VIP Membership <br /> 1 BULAN
                    </h4>
                    {/* <p className="text-center">( 4 - 10 September )</p> */}
                  </div>
                  <div className="__wrappe-text-desc-pricing">
                    <h3 className="text-center">
                      <del>Rp 212.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 199.000</h2>
                    <p className="text-center">
                      <b>Hemat 7%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access VIP Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access Premium Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access FREE Course​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Monthly QnA With Coaches​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Exclusive Live Webinar​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Support system by LavSquad​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Group Challenge​</b>
                        </p>
                      </Col>
                    </Row>
                    <p class="text-center">
                      Semua benefit diatas selama 1 bulan
                    </p>
                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://cart.lovecoach.id/product/vip-1-bulan-thr-spesial-offer"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Join
                            Sekarang
                          </Button>{" "}
                        </a>
                      </Col>
                      {/* <Col lg={12}>
                        <a
                          href="https://wa.link/g2h88l"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Desma
                          </Button>{" "}
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="__em-pricing-spesial-offer mrgn-reg">
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">
                      VIP Membership <br /> 3 BULAN
                    </h4>
                    {/* <p className="text-center">( 4 - 10 September )</p> */}
                  </div>
                  <div className="__wrappe-text-desc-pricing">
                    <h3 className="text-center">
                      <del>Rp 636.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 499.000</h2>
                    <p className="text-center">
                      <b>Hemat 20%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access VIP Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access Premium Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access FREE Course​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Monthly QnA With Coaches​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Exclusive Live Webinar​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Support system by LavSquad​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Group Challenge​</b>
                        </p>
                      </Col>
                    </Row>
                    <p class="text-center">
                      Semua benefit diatas selama 3 bulan
                    </p>
                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://cart.lovecoach.id/product/vip-3-bulan-thr-spesial-offer"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Join
                            Sekarang
                          </Button>{" "}
                        </a>
                      </Col>
                      {/* <Col lg={12}>
                        <a
                          href="https://wa.link/g2h88l"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Desma
                          </Button>{" "}
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </Col>
              <Col lg={4} md={4}>
                <div className="__em-pricing-spesial-offer mrgn-reg">
                  <div className="__wrappe-text-desc-pricing mt-3">
                    <h4 className="text-center">
                      VIP Membership <br /> 12 BULAN
                    </h4>
                    {/* <p className="text-center">( 4 - 10 September )</p> */}
                  </div>
                  <div className="__wrappe-text-desc-pricing">
                    <h3 className="text-center">
                      <del>Rp 2.200.000</del>
                    </h3>
                    <h2 className="text-center">Rp. 1.499.000</h2>
                    <p className="text-center">
                      <b>Hemat 30%</b>
                    </p>
                  </div>
                  <div className="__em-text-list-included">
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access VIP Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access Premium Course</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>ALL Access FREE Course​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Monthly QnA With Coaches​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Exclusive Live Webinar​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Support system by LavSquad​​​</b>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1} xs={1}>
                        <FaCheckCircle className="text-success" />
                      </Col>
                      <Col xs>
                        <p>
                          <b>Group Challenge​</b>
                        </p>
                      </Col>
                    </Row>
                    <p class="text-center">
                      Semua benefit diatas selama 12 bulan
                    </p>
                    <Row className="__wrappe-button-em-price py-3">
                      <Col lg={12}>
                        <a
                          href="https://cart.lovecoach.id/product/vip-12-bulan-thr-spesial-offer"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="primary">
                            <FaTags className="text-white"></FaTags> Join
                            Sekarang
                          </Button>{" "}
                        </a>
                      </Col>
                      {/* <Col lg={12}>
                        <a
                          href="https://wa.link/g2h88l"
                          rel="noreferrer"
                          target="_blank"
                        >
                          <Button className="btn btn-block" variant="success">
                            <FaWhatsapp className="text-white"></FaWhatsapp>{" "}
                            Chat CS Desma
                          </Button>{" "}
                        </a>
                      </Col> */}
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="__em-section-any-problem">
              <Col lg={12} xs={12}>
                <h2 className="text-center">
                  Semakin cepat kamu join Emotional Mastery <br /> semakin cepat
                  kamu bisa :
                </h2>
              </Col>
              <div className="__em-mx-section">
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      <i>
                        <b>Punya skill mengontrol emosi</b> yang tidak pernah
                        kamu dapatkan sebelumnya
                      </i>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      <i>
                        Memahami emosi kamu sendiri{" "}
                        <b>
                          disaat tidak ada satupun yang bisa kamu ajak bicara..
                        </b>
                      </i>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      <i>
                        Miliki hubungan{" "}
                        <b>
                          romansa yang sehat, saling mengerti, minim drama dan
                          hemat air mata..
                        </b>
                      </i>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      <i>
                        <b>Mampu memahami dan menyampaikan ekspektasi</b> yang
                        selama ini sering bikin kalian salah paham
                      </i>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      <i>
                        <b>Meminimalisir stress akibat mood swing</b> yang
                        selama ini gak bisa kamu kontrol
                      </i>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={1} xs={1}>
                    <FaCheckCircle className="text-success" />
                  </Col>
                  <Col xs>
                    <p>
                      <i>
                        <b>
                          Tetap tenang menghadapi apapun masalahmu hari ini,
                        </b>{" "}
                        entah pekerjaan, bisnis, romansa, masalah keluarga, dan
                        sebagainya.
                      </i>
                    </p>
                  </Col>
                </Row>
                <Col lg={12} xs={12}>
                  <div className="__em-button-signUp __width-button-em">
                    <a href="#pricingbottom">
                      <Button variant="primary" size="md">
                        Ya Saya Mau Daftar!
                      </Button>
                    </a>
                    <a href="#pricingbottom">
                      <p className="text-center text-drak pt-2">
                        (Klik di sini untuk instan akses sekarang)
                      </p>
                    </a>
                  </div>
                </Col>
              </div>
            </Row>

            <Row className="__margin-top-bottom">
              <Col lg={12}>
                <div className="__wrappe-who-pricing">
                  <h3 className="text-center">
                    Lihat, dengar dan rasakan testimoni jujur dari alumni online
                    training sebelumnya…
                  </h3>
                </div>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “Mindset saya tentang cinta, pria, jodoh dan emosi
                        berubah. Sekarang saya lebih merasa terarah untuk
                        hal-hal tersebut”
                      </p>
                      <p className="text-center">
                        <b>-Khonita</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “HARUS IKUT! Membuka wawasan dan kesadaran banget untuk
                        modal kita membangun hubungan romansa, jadi gak sabar
                        buat praktek.”
                      </p>
                      <p className="text-center">
                        <b>-Indah Rianti</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “Sekarang aku lebih bisa mengontrol sikap ketika bertemu
                        pria yang aku suka sehingga aku tidak terlalu bucin, dan
                        aku selalu ingat kata Coach Jose “jangan
                        menginvestasikan lebih besar dari apa yang dia
                        investasikan”
                      </p>
                      <p className="text-center">
                        <b>-Fitri</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “Saya bisa lebih mudah mengenal kekurangan dan kesalahan
                        saya dalam kehidupan romansa yang selama ini banyak
                        membuat saya gagal. Mindset saya pun lebih terbuka
                        tentang kehidupan romansa saya ke depannya”
                      </p>
                      <p className="text-center">
                        <b>-Ratih</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “Punya mindset baru. Tahu rahasia2 tentang attraction
                        yang sebelumnya gak tahu”
                      </p>
                      <p className="text-center">
                        <b>-Ellen</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “Paradigma yang salah jadi terbuka dan lebih optimis
                        menjalani hidup romansa ke depannya”
                      </p>
                      <p className="text-center">
                        <b>-Felicia</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “So far jadi lebih baik dalam kehidupan percintaan. Bisa
                        ngelepasin diri dari Toxic Relationship. Ngeikhlasin
                        orang dan keliatan gak needy lagi.”
                      </p>
                      <p className="text-center">
                        <b>-Cancia</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “Setelah mengikuti kelas Mastery banyak ilmu yang
                        didapat. Bagus ini materinya. Tinggal di praktekan
                        ilmunya.. Ternyata selama ini ada beberapa hal gak tepat
                        yang selalu saya lakukan.”
                      </p>
                      <p className="text-center">
                        <b>-Heni</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="my-3">
                  <Card.Body>
                    <div className="__wrappe-testimoni-text">
                      <p className="text-center">
                        “Seneng banget setelah mengikuti kelas mastery kemarin.
                        Mindsetku tentang romansa jadi terbuka selama ini yang
                        bikin kehidupan romansaku selalu gagal karena mindsetku
                        tentang romansa yang salah
                      </p>
                      <p className="text-center">
                        <b>-Nanda</b>
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={12}>
                <div className="__wrappe-who-pricing">
                  <h3 className="text-center">
                    Dan masih banyak lagi ratusan testimoni jujur lainnya yang
                    tidak akan cukup ditampilkan disini. Tunggu apalagi?
                  </h3>
                </div>
              </Col>
              <Col lg={12} xs={12}>
                <div className="__em-button-signUp __width-button-em">
                  <a href="#pricingbottom">
                    <Button variant="primary" size="md">
                      Ya Saya Mau Daftar!
                    </Button>
                  </a>
                  <a href="#pricingbottom">
                    <p className="text-center text-drak pt-2">
                      (Klik di sini untuk instan akses sekarang)
                    </p>
                  </a>
                </div>
              </Col>
            </Row>

            <Row className="__margin-top-bottom mb-5">
              <Col lg={12}>
                <div className="__wrappe-who-pricing">
                  <h3 className="text-center">About Creator</h3>
                </div>
              </Col>
            </Row>

            <Row className="__boder-em-why">
              <Col lg={4}>
                <div className="__wrappe-images-why-em mt-4">
                  <Image
                    className="img-fluid"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Jose-Aditya.png"
                  />
                </div>
              </Col>
              <Col lg={8}>
                <div className="__wrappe-desc-text-why-em">
                  <h3>Jose Aditya, Love Life Coach</h3>
                  <p>Pelatih Percintaan Wanita #1 di Indonesia.</p>
                  <p>
                    Dengan niat awal untuk membantu sang kakak perempuan untuk
                    menggapai cinta, Jose Aditya melakukan riset mendalam
                    mengenai kehidupan cinta wanita dari aspek psikologis,
                    biologis, dan neuroscience.
                  </p>
                  <p>
                    Impiannya kini adalah membantu 1 juta wanita untuk bahagia
                    dalam kehidupan cintanya.
                  </p>
                  <p>
                    Karena ia percaya, wanita yang bahagia bisa membuat dunia
                    lebih indah.
                  </p>
                  <p>
                    Dan kini telah berhasil menyentuh kehidupan cinta puluhan
                    ribu wanita Indonesia.
                  </p>
                  <p>Jose Aditya rutin membagikan tulisannya di situs</p>
                  <p>
                    <a
                      href="https://lovecoach.id/"
                      rel="noreferrer"
                      target="_BLANK"
                    >
                      www.lovecoach.id
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="__boder-em-why">
              <Col lg={4}>
                <div className="__wrappe-images-why-em mt-4">
                  <Image
                    className="img-fluid"
                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/Irvan-Lie.png"
                  />
                </div>
              </Col>
              <Col lg={8}>
                <div className="__wrappe-desc-text-why-em">
                  <h3>Irvan Irawan, Master LoveCoach.id</h3>
                  <p>
                    Irvan Irawan Jie adalah seorang MBA dari University of
                    Central Oklahoma. Saat ini ia adalah President dari
                    Meta-Coach Foundation Indonesia dan bersama dengan Jose
                    Aditya, menjadi Master Coach di LoveCoach.id.
                  </p>
                  <p>
                    Coach Irvan mendalami Neuro-Semantics dan Meta-Coaching
                    untuk mengisi kehampaan dan mencari kebahagiaan yang sejati
                    dalam hidupnya walaupun terlihat dari luar baik-baik saja.
                  </p>
                  <p>
                    Bersama LoveCoach.id, ia ingin membagikan formula
                    kebahagiaan agar wanita Indonesia memiliki kehidupan romansa
                    yang lebih baik.
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="__margin-top-bottom mb-5">
              <Col lg={12}>
                <div className="__wrappe-who-pricing">
                  <h3 className="text-center">F A Q</h3>
                </div>
              </Col>
              <Col lg={12}>
                <div className="mt-5">
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        <p>1. Maksudnya membership VIP ALL Access apa ya?</p>
                        {/* <p>Apa itu Emotional Mastery?</p> */}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <p>
                            Membership VIP adalah kategori membership di
                            platform Trulav.id dimana kamu bisa mengakses semua
                            kategori course mulai dari FREE, Premium dan VIP.
                            <br />
                            <br />
                            Hingga saat ini ada lebih dari 100+ video course
                            yang tersedia di Trulav.id <br />
                            <br />
                            Dengan join VIP membership, kamu bisa mengakses
                            ratusan video course dengan harga yang jauh lebih
                            murah daripada membeli satuan. Plus keuntungan
                            fasilitas lainnya seperti support system dan bisa
                            ikutan Exclusive Webinar GRATIS tanpa perlu biaya
                            tambahan.
                            {/* <b>Emotional Mastery</b> adalah Online Training
                            dimana kamu akan mempelajari cara mengendalikan dan
                            memanfaatkan emosi yang kamu punya. Yang awalnya
                            kamu kira menghambat tapi justru berguna membantumu
                            mencapai tujuan apapun hanya karena kamu tau "cara
                            pakainya" */}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="mt-3">
                      <Accordion.Toggle as={Card.Header} eventKey="1">
                        2. Apa yang terjadi setelah pembayaran saya berhasil?
                        {/* <p>Tapi Saya gaptek nih, cocok ngga buat Saya?</p> */}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <Card.Body>
                          <p>
                            Kamu akan menerima email berupa akses ke akun
                            membership Trulav milikmu. Jika kamu sudah punya
                            akun Trulav sebelumnya, kamu bisa langsung login
                            untuk mengakses video course yang kamu inginkan.
                            {/* Online Training diperuntukkan untuk siapapun yang
                            mempunyai keinginan untuk meng-upgrade diri. Dan
                            saya beserta tim telah merancang Online Training ini
                            agar mudah dipahami dan menyenangkan. */}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="mt-3">
                      <Accordion.Toggle as={Card.Header} eventKey="2">
                        <p>
                          3. Bagaimana jika membership saya habis?
                          {/* Bagaimana jika Saya baru pertama kali ikutan Online
                          Training? */}
                        </p>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <p>
                            Setelah durasi membershipmu habis, kamu bisa lakukan
                            perpanjangan dengan harga normal.
                            {/* Untuk itu, kamu tidak perlu khawatir. Selalu ada
                            pengalaman pertama bagi setiap orang. Online
                            Training ini didesain mudah, simple dan ringan yang
                            akan membuat pengalaman pertama belajar kamu menjadi
                            menyenangkan! */}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="mt-3">
                      <Accordion.Toggle as={Card.Header} eventKey="3">
                        <p>
                          4. Kapan promo THR ini berakhir?
                          {/* Jika saya berhalangan hadir, apakah ada rekaman
                          trainingnya? */}
                        </p>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <p>
                            Promo ini berakhir tanggal 26 April pukul 20:00 WIB
                            {/* Kamu tidak perlu khawatir karena akan ada Audio
                            rekaman yang akan diberikan melalui email oleh tim
                            sehingga kamu bisa tetap mendapatkan materi yang
                            sangat bermanfaat */}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="mt-3">
                      <Accordion.Toggle as={Card.Header} eventKey="4">
                        <p>
                          5. Bagaimana jika saya sudah memiliki membership
                          sebelumnya?
                          {/* Apakah online trainingnya bisa diakses melalui HP? */}
                        </p>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <p>
                            Membership kamu akan terupdate sesuai durasi
                            membership yang kamu pilih
                            {/* Tentu saja bisa, karena kamu bisa menggunakan Zoom
                            melalui HPmu */}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card className="mt-3">
                      <Accordion.Toggle as={Card.Header} eventKey="5">
                        6. Apakah ada nomor WhatsApp yang bisa saya hubungi?
                        {/* <p> Mengapa saya perlu daftar sekarang? </p> */}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <p>
                            Kamu bisa hubungi tim kami via WhatsApp
                            0812-1913-5268 (Fakhri) Atau email di
                            support@trulav.id
                            {/* Kamu perlu ikut Online Trainingnya sekarang karena
                            hanya diadakan satu tahun sekali dan Promonya hanya
                            berlaku untuk saat ini saja! */}
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    {/* <Card className="mt-3">
                      <Accordion.Toggle as={Card.Header} eventKey="6">
                        <p>Masih ada pertanyaan?</p>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <p>
                            Jika kamu masih punya pertanyaan seputar Online
                            Training ini jangan sungkan untuk menghubungi tim
                            saya melalui email cs@lovecoach.id atau melalui
                            WhatsApp di nomor +62812-1913-5268 (Fakhri) &
                            +62812-8114-5328 (Desma)
                          </p>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card> */}
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </LazyLoad>
        {/* end Section six */}
      </Layout>
    </>
  )
}
